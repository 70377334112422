@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
/* purgecss start ignore */

body {
  background-color:white;
}

/* purgecss end ignore */
/*purgecss start ignore*/

:root {
  --black--: #343a40;
  --purple--: #571f9c;
  --ash--: #f2f2f2;
  --white--: #ffffff;
  --blue--: #007bff;
  --deepblue--: #0056b3;
}

html{
  height: 100%;
}

#root {
  height: 100%;
}

body{
  font-family: 'Poppins' !important;
  height: 100%;
}

.App {
  font-family: 'Poppins', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-select {
  position: relative;
  font-family: Arial;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: DodgerBlue;
}

/*style the arrow inside the select element:*/

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/

.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/

.select-items div,.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*style items (options):*/

.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/

.select-hide {
  display: none;
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

/*purgecss end ignore*/
/* purgecss start ignore */

.productView_heading__y-vi1{
  display: flex;
  align-items: center;
  position: absolute;
  color: #494949;
  width: 163px;
  height: 30px;
  left: 6rem;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
}

.productView_para__2injm{
  position: absolute;
  width: 267px;
  height: 72px;
  left: 54px;
  top: 4rem;
  font-family:'Poppins', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #454545;
}

.productView_image__3wFVu{
  position: absolute;
  width: 374px;
  height: 243px;
  left: 1px;
  top: 199px;
  /* background: url("/images/image1.png"); */
  border-radius: 12px;
}

.productView_card__3h-XM {
  position: absolute;
  /* width: 335px;
height: 244px; */
  left: 20px;
  top: 402px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-items: left;
  font-family: 'Poppins', sans-serif;
}

.productView_button__EJRKu {
  font-family: 'Poppins', sans-serif;
  width: 160px;
  height: 42px;
  background-color: white;
  border: 1px solid #4A79F3;
  /* box-sizing: border-box; */
  border-radius: 12px;
  padding: 8px;
  margin: 8px;
}

.productView_button__EJRKu:hover{
  background-color: #5860F3;
  color:white;
}

/* purgecss end ignore */
/* purgecss start ignore */

.heading{
  display: flex;
  align-items: center;
  position: absolute;
  color: #494949;
  /* width: 163px; */
  /* height: 30px; */
  left: 6rem;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
}

html,body {
  margin:0;
  padding:0;
}

.imageOverlapBox{
  text-align: center;
  width: 316px;
  align-self: center;
  /* overflow-y: overlay; */
  z-index: 10;
  position: absolute;
  top: 376px;
  background-color: white;
  border-radius: 25px;
  padding-top: 21px;
}

.imageOverlapBoxVerified{
  text-align: center;
  width: 316px;
  align-self: center;
  /* overflow-y: overlay; */
  z-index: 10;
  position: absolute;
  top: 274px;
  background-color: white;
  border-radius: 25px;
  padding-top: 21px;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.05);
}

.imageOverlapBoxVerified>div{
  display: flex;
  flex-direction: column;
  padding-inline: 11px;
}

.imageOverlapBoxVerified>div>div{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.imageOverlapBoxVerified>div>div>p:nth-child(1){
  align-self: flex-start;
  margin-inline: 5px;
  color: #797979;
}

.imageOverlapBoxVerified>div>div>p:nth-child(2){
  align-self: flex-start;
  font-weight: 900;
  color:#323232;
  margin-inline: 5px;
}

.button:hover{
  background-color: #5860F3;
  color:white;
}

/* .ImgProduct{
    flex-wrap: nowrap;
    
} */

.mainContainer{
  width: 100%;
  /* overflow-x:hidden; */
}

.detailsContainer{
  overflow-x:hidden;
}

/* .tabsContainer{
    width: 100%;
} */

@media all and (max-width: 1408px) {
  .ImgProduct{
    width: 50% !important;
  }

  .detailsContainer{
    width: 50% !important;
  }

  .tabsContainer{
    margin-top: 136px;
    /* margin-inline: 13vw; */
  }

  .imageOverlapBox{
    top:377px;
  }

  .mainContainer{
    flex-wrap: wrap;
  }
}

@media all and (min-height: 800px) {
  .imageOverlapBox{
    top:340px;
  }

  .tabsContainer{
    margin-top: 200px;
  }
}

@media all and (min-width: 1400px) and (min-height: 800px) {
  .tabsContainer{
    margin-top: 0px;
  }
}

@media all and (max-width: 1390px) {
  .tabsContainer{
    align-self: center;
    width: 100% !important;
    padding-inline: 40px !important;
  }

  .tabsInfo{
    padding-inline: 10px !important;
  }
}

@media all and (max-width: 700px) {
  .tabsInfo{
    padding-inline: 50px !important;
  }
}

@media all and (max-width: 400px) {
  .tabsInfo{
    padding-inline: 40px !important;
  }
}

@media all and (max-width: 900px) {
  .ImgProduct{
    width: 100% !important;
  }

  .detailsContainer{
    margin-top: 252px;
    width: 100% !important;
  }

  .tabsContainer{
    width: 100% !important;
    margin-top: 0px;
  }

  .imageOverlapBox{
  }

  .mainContainer{
    flex-wrap: wrap;
  }
}

@media all and (max-width: 800px) {
  .mainContainer{
    overflow-x: hidden!important;
  }
}

@media all and (max-width: 450px) {
  .consumeText>p:nth-child(1){
    font-size: 15px!important;
  }

  .consumeText>p:nth-child(2){
    font-size: 14px!important;
  }

  .manfact>p:nth-child(1){
    font-size: 12px!important;
  }

  .manfact>p:nth-child(2){
    font-size: 13px!important;
  }

  .expDate>p:nth-child(1){
    font-size: 12px!important;
  }

  .expDate>p:nth-child(2){
    font-size: 13px!important;
  }
}

@media all and (max-width:400px){
  .productName{
    font-size: 15px!important;
  }

  .productPrice{
    font-size: 16px!important;
  }

  .productDescription{
    font-size: 13px!important;
  }

  .batchNo{
    font-size: 14px!important;
  }

  .batchName{
    font-size: 15px!important;
  }

  .batchAdd{
    font-size: 14px!important;
  }
}

/* purgecss end ignore */
/* purgecss start ignore */

body{
  margin: 0px;
  padding: 0px;
}

.navbar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  /* height: 10%; */
  margin-left: 15px;
  margin-right: 15px
}

.navChild:nth-child(1){
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 14%;
}

.navChild:nth-child(2){
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 14%;
}

@media all and (max-width:450px){
  .navChild:nth-child(2)>img:nth-child(1){
    height: 26px!important;
    margin-right: 6px;
  }

  .navChild:nth-child(2)>img:nth-child(2){
    height: 51px!important;
    margin-left: 6px;
  }

  .navChild:nth-child(1)>h1{
    font-size:25px;
    margin-left: 8px;
  }
}

.navChild>img{
  align-self: center;
}

.nameDetails{
  /* height: 5%; */
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0em;
  margin-left: 15px;
  margin-right: 15px;
}

@media all and (max-width:450px){
  .nameDetails{
    font-size: 26px;
  }
}

.midDetails{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  /* height: 8%; */
}

@media all and (max-width:450px){
  .midDetails>p:nth-child(1){
    font-size: 15px!important;
  }

  .midDetails>p:nth-child(2){
    font-size: 14px!important;
  }
}

.midDetails>p:nth-child(1){
  width: 173px;
  height: 39px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 39px;
  align-self: center;
  color: #494949;
}

.midDetails>p:nth-child(2){
  width: 103px;
  height: 30px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  /* identical to box height */
  align-self: center;
  color: #5860F3;
}

.midPhoto{
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media all and (max-width: 940px){
  .midPhoto{
    height: 25%;
  }
}

@media all and (max-width:751px){
  .midPhoto{
    overflow: auto;
    white-space: nowrap;
    /* width: 80%!important; */
  }

  .midPhoto>img{
    width: 60%!important;
    margin:0px 6px 0px 6px;
  }
}

@media all and (max-width:484px){
  .midPhoto{
    overflow: auto;
    white-space: nowrap;
    height: 25%;
  }

  .midPhoto>img{
    width: 60%!important;
    margin:0px 3px 0px 3px;
  }
}

.midPhoto>img:nth-child(1){
  border-radius:20px;
  width: 49%;
  height: 100%;
}

.midPhoto>img:nth-child(2){
  width: 49%;
  height: 100%;
  border-radius:20px;
}

.itemSection{
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  margin-right: 15px;
  justify-content: center;
  margin-top:25px;
  min-height:50%;
  flex-wrap: wrap;
}

.itemSection>div{
  width: 18%;
  height: 160px;
  background: #F9F9F9;
  border: 1.00962px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:10px;
  align-self: center;
}

@media  all and (max-width: 1400px) {
  .itemSection>div{
    width: 25%;
  }
}

@media all and (max-width:551px){
  .itemSection>div{
    width: 40%;
  }
}

.itemSection>div>img{
  align-self: center;
}

.itemSection>div>p{
  align-self: center;
}

footer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: relative;
  margin: 0px !important;
  /* margin-top:20px; */
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.04);
}

footer>div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 17px;
}

footer>div>img{
  align-self: center;
}

footer>div>p{
  align-self: center;
}

/* purgecss end ignore */
/* purgecss start ignore */

.webcamCapture{
  position:relative;
}

.Capture_button{
  position:absolute;
  bottom:1%;
  left:50%;
  transform:translate(-50%,-50%);
  cursor:pointer;
  /* color:black; */
}

.Capture_button>img{
  cursor:pointer;
}

.webcamPage{
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}

.thunder_Icon{
  position:absolute;
  bottom:78%;
  cursor:pointer;
}

.webcam_text{
  position:absolute;
  bottom:84%;
  color:white;
  width:48%;
  text-align:center;
}

.webcam_heading{
  position:absolute;
  color:white;
  bottom:93%;
  font-weight: 600;
}

.leftArrow{
  position:absolute;
  bottom:93%;
  right:76vw;
  cursor:pointer;
}

.webcamCapture>section{
  overflow : none ;
  position : block ;
  width:106% !important;
  padding-top:93% !important;
}

/* purgecss end ignore */
