
body{
    margin: 0px;
    padding: 0px;
}

.navbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    /* height: 10%; */
    margin-left: 15px;
    margin-right: 15px
}


.navChild:nth-child(1){
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 14%;

}
.navChild:nth-child(2){
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    min-width: 14%;

}

@media all and (max-width:450px){
.navChild:nth-child(2)>img:nth-child(1){
    height: 26px!important;
    margin-right: 6px;
}
.navChild:nth-child(2)>img:nth-child(2){
    height: 51px!important;
    margin-left: 6px;
}
.navChild:nth-child(1)>h1{
    font-size:25px;
    margin-left: 8px;
}
}
.navChild>img{
    align-self: center;
}

.nameDetails{
    /* height: 5%; */
font-size: 33px;
font-style: normal;
font-weight: 400;
line-height: 54px;
letter-spacing: 0em;
margin-left: 15px;
margin-right: 15px;

}

@media all and (max-width:450px){
    .nameDetails{
        font-size: 26px;
    }
}
.midDetails{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 15px;
    margin-right: 15px;
    /* height: 8%; */
}
@media all and (max-width:450px){
    .midDetails>p:nth-child(1){
        font-size: 15px!important;
    }
    .midDetails>p:nth-child(2){
        font-size: 14px!important;
    }
}

.midDetails>p:nth-child(1){
    
width: 173px;
height: 39px;

font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 19px;
line-height: 39px;
align-self: center;
color: #494949;

}

.midDetails>p:nth-child(2){

  
width: 103px;
height: 30px;


font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 17px;
line-height: 30px;
/* identical to box height */
align-self: center;
color: #5860F3;
}
.midPhoto{
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}

@media all and (max-width: 940px){
.midPhoto{
    height: 25%;
}
}
@media all and (max-width:751px){
    .midPhoto{
        overflow: auto;
        white-space: nowrap;
        /* width: 80%!important; */
    }
    .midPhoto>img{
        width: 60%!important;
        margin:0px 6px 0px 6px;
    }

}


@media all and (max-width:484px){
    .midPhoto{
        overflow: auto;
        white-space: nowrap;
      height: 25%;
    }
    .midPhoto>img{
        width: 60%!important;
        margin:0px 3px 0px 3px;
    }

}

.midPhoto>img:nth-child(1){
    border-radius:20px;
    width: 49%;
height: 100%;

}
.midPhoto>img:nth-child(2){
    width: 49%;
height: 100%;
border-radius:20px;
}

.itemSection{
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-right: 15px;
    justify-content: center;
    margin-top:25px;
    min-height:50%;
    flex-wrap: wrap;

}
.itemSection>div{
  
width: 18%;
height: 160px;
background: #F9F9F9;
border: 1.00962px solid #F3F3F3;
box-sizing: border-box;
border-radius: 20px;
display: flex;
flex-direction: column;
justify-content: center;
margin:10px;
align-self: center;
}
@media  all and (max-width: 1400px) {
    .itemSection>div{
        width: 25%;
    }
}

@media all and (max-width:551px){
    .itemSection>div{
        width: 40%;
    }
}

.itemSection>div>img{
    align-self: center;
}
.itemSection>div>p{
    align-self: center;
}

footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    margin: 0px !important;
    /* margin-top:20px; */
    box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.04);
}
footer>div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 17px;
}

footer>div>img{
    align-self: center;
}

footer>div>p{
    align-self: center;
}