@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.heading{
    display: flex;
    align-items: center;
    position: absolute;
    color: #494949;

    /* width: 163px; */
    /* height: 30px; */
    left: 6rem;
        
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */  
    
}

html,body {
    margin:0;
    padding:0;
    
   
   
    }


.imageOverlapBox{
    text-align: center;
    width: 316px;
    align-self: center;
    /* overflow-y: overlay; */
    z-index: 10;
    position: absolute;
    top: 376px;
    background-color: white;
    border-radius: 25px;
    padding-top: 21px;
}
.imageOverlapBoxVerified{
    text-align: center;
    width: 316px;
    align-self: center;
    /* overflow-y: overlay; */
    z-index: 10;
    position: absolute;
    top: 274px;
    background-color: white;
    border-radius: 25px;
    padding-top: 21px;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.05);

}
.imageOverlapBoxVerified>div{
    display: flex;
    flex-direction: column;
    padding-inline: 11px;
}
.imageOverlapBoxVerified>div>div{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.imageOverlapBoxVerified>div>div>p:nth-child(1){
    align-self: flex-start;
    margin-inline: 5px;
    color: #797979;

  
}
.imageOverlapBoxVerified>div>div>p:nth-child(2){
    align-self: flex-start;
    font-weight: 900;
    color:#323232;
    margin-inline: 5px;
}
.button:hover{
    background-color: #5860F3;
    color:white;
}

/* .ImgProduct{
    flex-wrap: nowrap;
    
} */

.mainContainer{
    width: 100%;
    /* overflow-x:hidden; */
}
.detailsContainer{
    overflow-x:hidden;
}

/* .tabsContainer{
    width: 100%;
} */

@media all and (max-width: 1408px) {
    .ImgProduct{
width: 50% !important;
    }
    .detailsContainer{
        width: 50% !important;
    }
    .tabsContainer{
        
        margin-top: 136px;
        /* margin-inline: 13vw; */

    }
    .imageOverlapBox{
      
        top:377px;
    }
    .mainContainer{
        flex-wrap: wrap;
    }
}
@media all and (min-height: 800px) {
.imageOverlapBox{
    top:340px;
}
.tabsContainer{
    margin-top: 200px;
    
}
}

@media all and (min-width: 1400px) and (min-height: 800px) {
    .tabsContainer{
        margin-top: 0px;
        
    }
}
@media all and (max-width: 1390px) {
    .tabsContainer{
        align-self: center;
        width: 100% !important;
        padding-inline: 40px !important;
    }
    .tabsInfo{
        padding-inline: 10px !important;
    }

}

@media all and (max-width: 700px) {
   
    .tabsInfo{
        padding-inline: 50px !important;
    }

}
@media all and (max-width: 400px) {
   
    .tabsInfo{
        padding-inline: 40px !important;
    }

}

@media all and (max-width: 900px) {
    .ImgProduct{
width: 100% !important;
    }
    .detailsContainer{
        margin-top: 252px;
        width: 100% !important;
    }
    .tabsContainer{
        width: 100% !important;
        margin-top: 0px;
        
    }
    .imageOverlapBox{
      
        
    }
    .mainContainer{
        flex-wrap: wrap;
       
    }
}
@media all and (max-width: 800px) {
    .mainContainer{
        overflow-x: hidden!important;
    }
}
@media all and (max-width: 450px) {
    .consumeText>p:nth-child(1){
        font-size: 15px!important;
    }
    .consumeText>p:nth-child(2){
        font-size: 14px!important;
    }
    .manfact>p:nth-child(1){
        font-size: 12px!important;
    }
    .manfact>p:nth-child(2){
        font-size: 13px!important;
    }
    .expDate>p:nth-child(1){
        font-size: 12px!important;
    }
    .expDate>p:nth-child(2){
        font-size: 13px!important;
    }
}

@media all and (max-width:400px){
.productName{
    font-size: 15px!important;
}
.productPrice{
    font-size: 16px!important;
}
.productDescription{
    font-size: 13px!important;
}
.batchNo{
    font-size: 14px!important;
}
.batchName{
    font-size: 15px!important;
}
.batchAdd{
    font-size: 14px!important;
}
}