@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

.heading{
    display: flex;
    align-items: center;
    position: absolute;
    color: #494949;

    width: 163px;
    height: 30px;
    left: 6rem;
        
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */  
    
}

.para{
    position: absolute;
width: 267px;
height: 72px;
left: 54px;
top: 4rem;

font-family:'Poppins', sans-serif;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
text-align: center;

color: #454545;
}

.image{
    position: absolute;
    width: 374px;
    height: 243px;
    left: 1px;
    top: 199px;    
    /* background: url("/images/image1.png"); */
    border-radius: 12px;  
}

.card {
    position: absolute;
/* width: 335px;
height: 244px; */
left: 20px;
top: 402px;

background: #FFFFFF;
box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.05);
border-radius: 20px;
padding: 1rem;
display: flex;
flex-direction: column;
justify-items: left;
font-family: 'Poppins', sans-serif;

}

.button {

font-family: 'Poppins', sans-serif;
width: 160px;
height: 42px;
background-color: white;
border: 1px solid #4A79F3;
/* box-sizing: border-box; */
border-radius: 12px;
padding: 8px;
 margin: 8px;
}

.button:hover{
    background-color: #5860F3;
    color:white;
}