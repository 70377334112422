.webcamCapture{
    position:relative;
    
}

.Capture_button{
    position:absolute;
    bottom:1%;
    left:50%;
  
    transform:translate(-50%,-50%);
    cursor:pointer;
    /* color:black; */

}
.Capture_button>img{
    cursor:pointer;
}

.webcamPage{
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: center;
}
.thunder_Icon{
    position:absolute;
    bottom:78%;
    cursor:pointer;
}

.webcam_text{
    position:absolute;
    bottom:84%;
    color:white;
    width:48%;
    text-align:center;
    
}
.webcam_heading{
    position:absolute;
    color:white;
    bottom:93%;
    font-weight: 600;
}
.leftArrow{
    position:absolute;
    bottom:93%;
    right:76vw;
    cursor:pointer;
}
.webcamCapture>section{
    overflow : none ;
    position : block ;
    width:106% !important;
    padding-top:93% !important;
}